import Urls from '../urls.js';
import BaseView from '../../../../scripts/utils/base-view.js';

export default class CustomerMenuView extends BaseView {
	constructor({
		el,
		cart
	}) {
		super(el);
		this.template_url = Urls.Templates.app;
		this.cart = cart ? this.parse_json(cart) : null;
		this.count = 0;
		this.update_count();
		this.bind_events();
		this.render();
	}

	dispatch_product_added () {
		const product_added_event = new CustomEvent('[add-to-cart]:product-added:ga-sent');

		document.dispatchEvent(product_added_event);
	}

	update_cart_handler (e) {
		var detail = e.detail;

		this.dispatch_product_added();

		if (detail && detail.Cart) {
			this.cart = this.parse_json(detail.Cart);
			this.update_count();
			this.render();
		}
	}

	bind_events () {
		document.addEventListener('[add-to-cart]:product-added', this.update_cart_handler.bind(this));
		document.addEventListener('[cart]:update', this.update_cart_handler.bind(this));
	}

	update_count () {
		this.count = 0;
		if (this.cart && this.cart.Products) {
			var products = this.cart.Products;
			if (products) {
				products.forEach(function (product) {
					this.count += parseInt(product.Qty);
				}.bind(this));
			}
		}
	}

	get_context() {
		return {
			cart: this.cart ? this.parse_json(this.cart) : null,
			count: this.count
		};
	}
}
