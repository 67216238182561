import SessionInfoModel from './models/info.js';
import SessionPingModel from './models/ping.js';

export default class Session {
	constructor () {
		this.settings = window.SiteSettings || {};
		this.product_id = window.__prodid && window.__prodid.length ? window.__prodid : '';
		this.model = new SessionInfoModel();
		this.fetched = false;
		if( this.product_id.length ) {
			this.model.product_id = this.product_id;
		}
		this.ping_interval = 0;
		this.ping_timer = null;
		this.set_tzo_cookie();
		this.fetch_session();
	}

	fetch_session () {
		this.model.fetch_info(this.product_id)
			.then(function () {
				this.fetched = true;
				if (this.settings.SESSION_PING_INTERVAL) {
					this.ping_interval = this.settings.SESSION_PING_INTERVAL * 1000;
					this.ping_session();
				}
			}.bind(this));
	}

	get (key) {
		if (this.model) {
			if (typeof this.model.get(key) !== 'undefined') {
				return Promise.resolve(this.model.get(key));
			} else {
				return new Promise(resolve => {
					document.addEventListener('[Session]:fetched', event => {
						var detail = event.detail;

						if (detail) {
							resolve(detail[key]);
						} else {
							resolve(null);
						}
					}, { once: true });
				});
			}
		}
	}

	product_in_cart () {
		var key = 'ProductInfo';

		return new Promise(function (resolve) {
			if (this.model && this.model.get(key)) {
				var options = {
					product: this.find_cart_product(this.product_id),
					product_info: this.model.get(key)
				};
				resolve(options);
			} else {
				document.addEventListener('[Session]:fetched', event => {
					var detail = event.detail;
					var options = {
						product: this.find_cart_product(this.product_id),
						product_info: event.detail[key]
					};

					if (detail) {
						resolve(options);
					} else {
						resolve(null);
					}
				}, { once: true });
			}
		}.bind(this));
	}

	find_cart_product (product_id) {
		var product = null;

		if (this.model.get('ShoppingCart')) {
			var products = this.model.get('ShoppingCart').Products;
			if (products.length > 0) {
				product = products.find(product => product.ProductId === product_id);
			}
		}
		return product;
	}

	ping_session () {
		var customer = this.model.get('Customer');
		var ping_model = new SessionPingModel();
		if (customer) {
			ping_model.save();
			this.ping_timer = setInterval(function () {
				ping_model.save();
			}, this.ping_interval);
		}
	}

	update () {
		if (this.ping_timer) {
			clearTimeout(this.ping_timer);
		}
		this.model.fetch_info()
			.then(function() {
				if(this.ping_interval) {
					this.ping_session();
				}
			}.bind(this) );
		return new Promise(function (resolve) {
			document.addEventListener('[Session]:fetched', function (event) {
				var detail = event.detail;
				if (detail) {
					resolve(detail);
				} else {
					resolve(null);
				}
			}, { once: true });
		});
	}

	set_tzo_cookie () {
		var d = new Date();
		var tzo = d.getTimezoneOffset();
		var time = d.getTime();

		d.setDate(d.getDate() + 365);
		document.cookie = `tzo=${tzo};expires=${d.toUTCString()};path=/`;

		var dateTimeFormat = Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions();
		var tz_name = dateTimeFormat.timeZone;

		document.cookie = `tz_name=${tz_name};expires=${d.toUTCString()};path=/`
	}
}
