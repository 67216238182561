// Do not use this on production.
// All templates should be precompiled.

import Template from './template.js';

export default class BaseView {
	constructor (el) {
		if (el instanceof Element) {
			this.el = el;
		} else {
			this.el = document.querySelector(el);
		}
	}

	get_context () {
		return {};
	}

	load_template () {
		if (this.template_url) {
			return Template.load(this.template_url);
		} else {
			throw new Error('BaseView.load_template require template_url');
		}
	}

	render () {
		this.load_template()
			.then(function(template) {
				this.render_template(template);
			}.bind(this));
	}

	render_template (template) {
		var context = this.get_context();
		if (this.el) {
			this.el.innerHTML = Template.render(template, context);
			this.rendered();
		}
	}

	render_partial (template, context) {
		if (template && context) {
			return Template.render(template, context);
		} else {
			throw new Error('BaseView.render_partial require templates and context objects');
		}
	}

	rendered () {
		const e = new Event('rendered');
		this.el.dispatchEvent(e);
		this.after_render();
	}

	after_render () {
		if (this.el) {
			this.el.dispatchEvent(new CustomEvent('rendered'));
		}
		this.bindSelectors();
		return true;
	}

	bindSelectors() {
		if (this.selectors && !!Object.keys(this.selectors).length) {
			Object.keys(this.selectors).forEach(key => {
				this[`${key}Element`] = this.el ?
					this.el.querySelector(this.selectors[key]) :
					document.querySelector(this.selectors[key]) ;
			});
		}
	}

	parse_json (json) {
		return JSON.parse(JSON.stringify(json));
	}

	html_render (context) {
		return this.load_template()
			.then(template => {
				if (template && context) {
					const html = Template.render(template, context);

					return Promise.resolve(html);
				} else {
					throw new Error('BaseView.html_render require templates and context objects');
				}
			});
	}
}
