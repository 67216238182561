import { fetcher } from '../../../../scripts/utils/fetch.js';
import Urls from '../urls.js';

export default class SessionPingModel {
	constructor (attributes = {}, options = {}) {
		this.attributes = attributes;
		this.option = options;
	}
	url () {
		return Urls.Api.ping;
	}
	save() {
		var url = this.url();
		return fetcher(url, {
			method: 'POST'
		});
	};
}