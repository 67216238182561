import CartMenuView from './views/menu.js';

export default class CartMenu {
	constructor({
		el,
		cart
	}) {
		this.view = new CartMenuView({
			el,
			cart
		});
	}
}
