import Session from '../../modules/session/js/app.js';
import CustomerMenu from '../../modules/customer-menu/js/app.js';
import CartMenu from '../../modules/cart-menu/js/app.js';
import QuoteMenu from '../../modules/quote-menu/js/app.js';
import {update_currency_settings} from './settings/currency.js';
import BusinessHoursApp from '../../modules/business-hours/js/app.js';
import {throttle} from '../utils/throttle.js';
import MainMenu from './common/main-menu.js';

new BusinessHoursApp();
var settings = window.SiteSettings || {};

window.Session = new Session();

window.Session.get('Currency')
	.then(function (currency) {
		update_currency_settings(currency);
	});

Promise.all([window.Session.get('Customer'), window.Session.get('Settings')])
	.then(function(results) {
		new CustomerMenu({
			el: '.app-customer-menu',
			customer: results[0],
			settings: results[1],
		});
	});

new MainMenu({
	toggler_selector: '.page-header .menu-toggler',
	menu_selector: '.main-menu'
});

document.addEventListener('handlebars:helpers-loaded', function () {
	window.Session.get('ShoppingCart')
		.then(function (cart) {
			new CartMenu({
				el: '.app-cart-menu',
				cart: cart
			});
		});

	window.Session.get('QuoteRequest')
		.then(function (quote) {
			new QuoteMenu({
				el: '.app-quote-menu',
				quote: quote
			});
		});
});