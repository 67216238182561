import { fetcher } from '../../../../scripts/utils/fetch.js';
import Urls from '../urls.js';

export default class SessionInfoModel {
	constructor (attributes = {}, product_id = null) {
		this.attributes = attributes;
	}
	url () {
		return this.product_id ? Urls.Api.info + '?product_id=' + this.product_id : Urls.Api.info;
	}
	fetch_info () {
		var url = this.url();
		return fetcher(url)
			.then(function (json) {
				this.attributes = json;
				this.fetched();
			}.bind(this));
	}
	fetched () {
		var fetched_event = new CustomEvent('[Session]:fetched', { detail: this.attributes });
		document.dispatchEvent(fetched_event);
	}
	get (key) {
		if (this.attributes) {
			return this.attributes[key];
		}
	}
}
