import CustomerMenuView from './views/menu.js';

export default class CustomerMenu {
	constructor({
		el,
		customer,
		settings
	}) {
		this.view = new CustomerMenuView({
			el,
			customer,
			settings
		});
	}
}
