import Urls from '../urls.js';
import BaseView from '../../../../scripts/utils/base-view.js';

export default class CustomerMenuView extends BaseView {
	constructor({
		el,
		quote
	}) {
		super(el);
		this.template_url = Urls.Templates.app;
		this.quote = quote ? quote : {};
		this.render();
	}

	get_context() {
		return {
			quote: this.quote,
			count: this.quote.Products ? this.quote.Products.length : 0
		};
	}

	after_render() {
		if (this.quote.Products) {
			this.el.classList.remove('d-none');
		}
	}
}
