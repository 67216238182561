import QuoteMenuView from './views/menu.js';

export default class QuoteMenu {
	constructor({
		el,
		quote
	}) {
		this.view = new QuoteMenuView({
			el,
			quote
		});
	}
}
