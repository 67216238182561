import Urls from '../urls.js';
import BaseView from '../../../../scripts/utils/base-view.js';
import Dropdown from '../../../../node_modules/bootstrap.native/dist/components/dropdown-native.esm.js';

export default class CustomerMenuView extends BaseView {
	constructor({
		el,
		customer,
		settings
	}) {
		super(el);
		this.template_url = Urls.Templates.app;
		this.customer = customer ? this.parse_json(customer) : null;
		this.settings = settings ? this.parse_json(settings) : null;
		this.bind_events();
		this.render();
	}

	login_handler (event) {
		var detail = event.detail;
		if (detail && detail.customer) {
			this.customer = detail.customer;
			this.render();
		}
	}

	bind_events () {
		document.addEventListener('[customer]:loggedin', this.login_handler.bind(this));
	}

	get_context () {
		const my_account_header_apps = this.settings ? this.parse_json(this.settings.CustomerHeaderMenuApps) : [];
		
		return {
			customer: this.customer ? this.parse_json(this.customer) : null,
			settings: this.settings ? this.parse_json(this.settings) : null,
			my_account_header_apps: my_account_header_apps,
			path: window.location.pathname
		};
	}

	after_render () {
		var dropdownToggle = this.el.querySelector('[data-toggle="dropdown"]');
		var customerMenuRenderedEvent = new CustomEvent('customer-menu-rendered');

		this.el.dispatchEvent(customerMenuRenderedEvent);

		if (dropdownToggle) {
			var dropdownInit = new Dropdown(dropdownToggle);
			var hideDropdown = () => dropdownInit.hide();

			dropdownToggle.parentNode.addEventListener('shown.bs.dropdown', () => {
				setTimeout(() => document.addEventListener('click', hideDropdown, { once: true }), 100);
			});
			dropdownToggle.parentNode.addEventListener('hide.bs.dropdown', () => {
				document.removeEventListener('click', hideDropdown);
			});
		}
	}
}