// Do not use this on production.
// All templates should be precompiled.

import {fetch_status, fetch_text, fetch_error, fetch_error_message} from './fetch.js';

var Template = {
	compiled: {},
	load: function (template_url) {
		var t = null;
		if (Object.prototype.hasOwnProperty.call(this.compiled, template_url)) {
			t = this.compiled[template_url];
			return new Promise(function (resolve) {
				return resolve(t);
			});
		} else {
			return fetch(template_url)
				.then(fetch_status)
				.then(fetch_text)
				.then(function (text) {
					t = this.compile(text, template_url);
					return t;
				}.bind(this))
				.catch(error => {
					error.text()
						.then(error_text => {
							fetch_error_message(error_text);
						});
				});
		}
	},
	compile: function(template, template_name) {
		if (!this.compiled[template_name]) {
			this.compiled[template_name] = Handlebars.compile(template);
		}
		return this.compiled[template_name];
	},
	render: function(template, context) {
		return template(context);
	}
};

export default Template;