import Dropdown from '../../../node_modules/bootstrap.native/dist/components/dropdown-native.esm.js';

export default class BusinessHoursApp {
	constructor() {
		this.businessHoursInit();
	}

	normalizeTime(value) {
		const result = [];

		if (value.search(/pm/i) >= 0) {
			if (parseInt(value) === 12) {
				result[0] = 12;
			} else {
				result[0] = parseInt(value) + 12;
			}
		} else if (value.search(/am/i) >= 0 && parseInt(value) === 12) {
			result[0] = 0;
		} else {
			result[0] = parseInt(value);
		}

		result[1] = parseInt(value.split(':')[1]);

		return result;
	}

	businessHours(hours_element) {
		const today = new Date();
		let todayDay = today.getDay();
		const hoursToday = hours_element.querySelector('.js-hours-today');
		const dropdownToggle = hours_element.querySelector('[data-bs-toggle="dropdown"]');
		let todayStart = null;
		let todayEnd = null;
		let activeDay = null;
		let opened = false;

		if (!hours_element) {
			return;
		}

		if (todayDay === 0) {
			todayDay = 7;
		}

		const companyTZ = hours_element.getAttribute('data-tz');
		const utc = today.getTime() + (today.getTimezoneOffset() * 60000);
		const companyTime = new Date(utc + (60000 * companyTZ));

		if (hours_element.querySelector('[data-day="8"]')) {
			activeDay = hours_element.querySelector('[data-day="8"]');
		} else {
			activeDay = hours_element.querySelector(`[data-day="${todayDay}"]`);
			if (!activeDay) {
				if (hours_element.querySelector('[data-day="9"]') && todayDay < 6) {
					activeDay = hours_element.querySelector('[data-day="9"]');
				}
			}
		}

		if (activeDay) {
			activeDay.classList.add('selected');
			hoursToday.innerHTML = '';
			hoursToday.appendChild(activeDay.querySelector('.hours-time').cloneNode(true));

			todayStart = this.normalizeTime(activeDay.querySelector('.hours-start').getAttribute('datetime'));
			todayEnd = this.normalizeTime(activeDay.querySelector('.hours-end').getAttribute('datetime'));

			if (todayEnd[0] === 0) {
				todayEnd[0] = 24;
			}

			if (todayStart[0] < companyTime.getHours() && todayEnd[0] > companyTime.getHours()) {
				opened = true;
			} else if (todayStart[0] === companyTime.getHours() && todayEnd[0] === companyTime.getHours()) {
				if (todayStart[1] <= companyTime.getMinutes() && todayEnd[1] >= companyTime.getMinutes()) {
					opened = true;
				}
			} else if (todayStart[0] === companyTime.getHours()) {
				if (todayStart[1] <= companyTime.getMinutes()) {
					opened = true;
				}
			} else if (todayEnd[0] === companyTime.getHours()) {
				if (todayEnd[1] >= companyTime.getMinutes()) {
					opened = true;
				}
			}

			if (opened) {
				hours_element.querySelector('.js-opened').classList.remove('hide');
			} else {
				hours_element.querySelector('.js-closed-now').classList.remove('hide');
			}
		} else {
			hours_element.querySelector('.js-closed-today').classList.remove('hide');
		}

		if (dropdownToggle) {
			new Dropdown(dropdownToggle);
		}
	}

	businessHoursInit() {
		const hours = document.querySelectorAll('.business-hours');

		hours.forEach(item => this.businessHours(item));
	}
}