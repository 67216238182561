function update_currency_settings (currency) {
	if (window.SiteSettings) {
		if (currency && currency.SiteCurrency) {
			window.SiteSettings.Currency = {
				currency: '' + currency.SiteCurrency.Symbol,
				currency_position: '' + currency.SiteCurrency.Position,
				decimals: '' + currency.SiteCurrency.Decimals,
				thousands_separator: '' + currency.SiteCurrency.ThousandsSeparator
			};
		}
		if (currency && currency.CartCurrency) {
			window.SiteSettings.CurrencyExchange = {
				currency: '' + currency.CartCurrency.Symbol,
				currency_position: '' + currency.CartCurrency.Position,
				decimals: '' + currency.CartCurrency.Decimals,
				thousands_separator: '' + currency.CartCurrency.ThousandsSeparator,
				rate: '' + currency.CartCurrency.Rate
			};
		}
	}
}

export {update_currency_settings};