export default class MainMenu {
	constructor ({
		toggler_selector,
		menu_selector
	}) {
		this.togglers = document.querySelectorAll(toggler_selector);
		this.menu = document.querySelector(menu_selector);

		if (this.togglers.length && this.menu) {
			this.bindEvents();
		}
	}

	bindEvents () {
		const sub_menu_togglers = this.menu.querySelectorAll('.has-sub-menu > a');
		const back_link = this.menu.querySelector('.back-link');

		this.togglers.forEach(toggler => {
			toggler.addEventListener('click', this.toggleMenu);
		});

		if (sub_menu_togglers.length > 0) {
			sub_menu_togglers.forEach(function (sub_menu_toggler) {
				sub_menu_toggler.addEventListener('click', this.showSubMenu.bind(this));
			}.bind(this));

			if (back_link) {
				back_link.addEventListener('click', this.hideSubMenu.bind(this));
			}
		}
	}

	toggleMenu (e) {
		e.preventDefault();
		document.body.classList.toggle('menu-visible');
	}

	showSubMenu (e) {
		const toggler = e.currentTarget;

		if (document.body.classList.contains('menu-visible')) {
			e.preventDefault();
			toggler.parentElement.classList.add('active');
			this.menu.classList.add('sub-menu-visible');
		}
	}

	hideSubMenu () {
		const active_menu = this.menu.querySelector('.has-sub-menu.active');

		this.menu.classList.remove('sub-menu-visible');
		if (active_menu) {
			setTimeout(() => {
				active_menu.classList.remove('active');
			}, 300);
		}
	}

}