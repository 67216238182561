function fetch_status(response) {
	if (response.ok) {
		return Promise.resolve(response);
	} else {
		return Promise.reject(response);
	}
}

function fetch_json(response) {
	return response.json();
}

function fetch_text(response) {
	return response.text();
}

function fetch_error_message(error) {
	let message = 'Something went wrong...';
	let sessionErr = false;

	if (error.includes('Message')) {
		error = JSON.parse(error);
	}

	if (error.Type && error.Type === 'SessionVerificationRequiredException') {
		sessionErr = true;
		if (window.verifySession) {
			window.verifySession();
		}
	} else if (!sessionErr) {
		if (error && typeof error === 'object') {
			if (error.exception && error.exception.Message) {
				message = error.exception.Message;
			} else if (error.responseJSON && error.responseJSON.Message) {
				message = error.responseJSON.Message;
			}
		}
		throw new Error(message);
	}
}

function fetch_error(error, custom_error) {
	if (custom_error) {
		return error.json();
	} else {
		if (error.name !== 'AbortError') {
			fetch_error_message(error);
		}
	}
}

function fetcher(url = '', options = {}) {
	const data = options.is_text ? fetch_text : fetch_json;

	return options.custom_error ?
		fetch(url, options).then(fetch_status).then(data) :
		fetch(url, options).then(fetch_status).then(data).catch(error => {
			if (typeof error === 'string') {
				throw new Error(error);
			} else {
				if (error.text && typeof error.text === 'function') {
					error.text()
						.then(error_text => {
							fetch_error_message(error_text);
						});
				} else if (options.signal) {
					if (!options.signal.aborted) {
						throw new Error(error);
					}
				} else {
					throw new Error(error);
				}
			}
		});
}

export {fetch_status, fetch_json, fetch_text, fetch_error_message, fetch_error, fetcher};